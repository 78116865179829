import { defineMessages } from 'react-intl';
import { ERROR_MESSAGES as idPrefix } from '../constants/translates';

const messages = defineMessages({
  title: {
    id: `${idPrefix}.title`,
    defaultMessage: 'Error'
  },
  authError: {
    id: `${idPrefix}.authError`,
    defaultMessage: 'auth error description'
  },
  serverError: {
    id: `${idPrefix}.serverError`,
    defaultMessage: 'server error description'
  },
  defaultError: {
    id: `${idPrefix}.defaultError`,
    defaultMessage: 'default error description'
  }
});

export default messages;
