import { ERROR_MESSAGES as idPrefix } from '../../constants/translates';

const messages = {
  [`${idPrefix}.title`]: 'Вхід до адмін панелі',
  [`${idPrefix}.authError`]: 'Невірне введена пошта або(та) пароль',
  [`${idPrefix}.serverError`]: 'Виникла помилка при виконнані запросу. Спробуйте пізніше',
  [`${idPrefix}.defaultError`]: 'Виникла помилка при виконнані запросу. Спробуйте пізніше'
};

export default messages;
