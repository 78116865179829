import { CHARACTER_CHOOSE_VIEW as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.title`]: 'Выберите персонажа',
  [`${idPrefix}.characterUser`]: 'Пользователь',
  [`${idPrefix}.characterJournalist`]: 'Журналист',
  [`${idPrefix}.characterBlogger`]: 'Блогер',
  [`${idPrefix}.goNextStep`]: 'Начать игру',
};

export default messages;
