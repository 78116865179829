import {
  Button,
  Dropdown, Menu, Radio, Table, Typography
} from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './AdminGamesList.i18n';

export default function AdminGamesListView({
  adminProfile, games, gamesTotal, gamesQuery,
  handleLogoutClick, handleStatusChange, handleTableChange, page,
  tableColumns, tablePreloader
}) {
  const { Title } = Typography;

  return (
    <div className="App__admin__gamesList">
      <div className="App__admin__header">
        <div className="App__admin__menu">
          <Dropdown
            overlay={(
              <Menu>
                <Menu.Item>
                  <Button
                    type="link"
                    onClick={handleLogoutClick}
                  >
                    <FormattedMessage {...messages.headerMenuLogout} />
                  </Button>
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              type="link"
            >
              {adminProfile.email}
            </Button>
          </Dropdown>
        </div>
      </div>
      {
        gamesQuery.status === 'all'
        && (
          <Title>
            <FormattedMessage {...messages.titleStatusAll} />
            (
              {gamesTotal}
            )
          </Title>
        )
      }
      {
        gamesQuery.status === 'finished'
        && (
          <Title>
            <FormattedMessage {...messages.titleStatusFinished} />
            (
            {gamesTotal}
            )
          </Title>
        )
      }
      <div className="App__admin__gamesList__filter">
        <Radio.Group onChange={handleStatusChange} value={gamesQuery.status}>
          <Radio value="all"><FormattedMessage {...messages.titleStatusAll} /></Radio>
          <Radio value="finished"><FormattedMessage {...messages.titleStatusFinished} /></Radio>
        </Radio.Group>
      </div>
      <Table
        className="App__admin__gamesList__table"
        columns={tableColumns}
        dataSource={games}
        loading={tablePreloader}
        onChange={handleTableChange}
        pagination={{
          current: Number(page),
          total: Number(gamesTotal)
        }}
        rowKey={record => record.id}
      />
    </div>
  );
}

AdminGamesListView.propTypes = {
  adminProfile: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  gamesTotal: PropTypes.number.isRequired,
  gamesQuery: PropTypes.shape().isRequired,
  handleLogoutClick: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  handleTableChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      dataIndex: PropTypes.string.isRequired,
      render: PropTypes.func,
      width: PropTypes.string
    })
  ).isRequired,
  tablePreloader: PropTypes.bool.isRequired
};
