import React from 'react';
import PropTypes from 'prop-types';
import GameStatsMiniList from '../../../elements/GameStatsList/GameStatsMiniList';

function GameHeader({
  character, stats, userCharacter
}) {
  return (
    <div className="App__gameHeader">
      <div className="App__gameHeader__character__avatar">
        {
          character.image
          && (<img src={character.image.path} alt={character.name} />)
        }
      </div>
      <div className="App__gameHeader__info">
        <div className="App__gameHeader__character__name">
          {character.name}
        </div>
        <GameStatsMiniList
          className="App__gameHeader__statsMiniList"
          stats={stats}
          userCharacter={userCharacter}
        />
      </div>
    </div>
  );
}

GameHeader.propTypes = {
  character: PropTypes.shape({
    image: PropTypes.shape({
      path: PropTypes.string
    }),
    name: PropTypes.string
  }).isRequired,
  stats: PropTypes.shape({}).isRequired,
  userCharacter: PropTypes.bool
};
GameHeader.defaultProps = {
  userCharacter: false
};

export default GameHeader;
