import { ADMIN_GAMES_LIST as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.headerMenuLogout`]: 'Вихід',
  [`${idPrefix}.titleStatusAll`]: 'Усі ігри',
  [`${idPrefix}.titleStatusFinished`]: 'Завершені ігри',
  [`${idPrefix}.tableColCharacterNotSelected`]: 'Не обран',
  [`${idPrefix}.tableColTitleCharacter`]: 'Персонаж',
  [`${idPrefix}.tableColTitleStatus`]: 'Статус',
  [`${idPrefix}.tableColTitleResult`]: 'Результат',
  [`${idPrefix}.tableColTitleDate`]: 'Дата',
  [`${idPrefix}.tableColStatusFinished`]: 'Завершена',
  [`${idPrefix}.tableColStatusNotFinished`]: 'Не завершена'
};

export default messages;
