import {
  RESET_GAME, SELECT_CHARACTER, SET_PLAYER_STATS, SET_QUESTIONS, UPDATE_CHARACTERS,
  UPDATE_GAME_STEP, UPDATE_PLAYER, UPDATE_PLAYER_STATS
} from '../constants/actionTypes';

export function resetGame() {
  return { type: RESET_GAME };
}

export function selectCharacter(selected) {
  return { type: SELECT_CHARACTER, selected };
}

export function setQuestions(questions) {
  return { type: SET_QUESTIONS, questions };
}

export function setPlayerStats(stats) {
  return { type: SET_PLAYER_STATS, stats };
}

export function updateCharacters(characters) {
  const mainCharacters = characters.filter(item => item.type === 'main');
  const supportCharacters = characters.filter(item => item.type === 'support');
  const newCharacters = {
    main: mainCharacters,
    support: supportCharacters
  };
  return { type: UPDATE_CHARACTERS, characters: newCharacters };
}

export function updateGameStep(step) {
  return { type: UPDATE_GAME_STEP, step };
}

export function updatePlayerStats(stats) {
  return { type: UPDATE_PLAYER_STATS, stats };
}

export function updatePlayer(player) {
  return { type: UPDATE_PLAYER, player };
}
