import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as adminProfileActions from '../../actions/adminProfile';
import * as pageActions from '../../actions/page';
import AdminGamesListView from './AdminGamesListView';
import messages from './AdminGamesList.i18n';

class AdminGamesList extends Component {
  state = {
    games: [],
    gamesTotal: 0,
    gamesQuery: {
      status: 'finished'
    },
    page: 1,
    tableColumns: [
      {
        title: (<FormattedMessage {...messages.tableColTitleCharacter} />),
        dataIndex: 'character',
        render: character => (
          character ? character.name : (<FormattedMessage {...messages.tableColCharacterNotSelected} />)
        ),
        width: '25%'
      },
      {
        title: (<FormattedMessage {...messages.tableColTitleStatus} />),
        dataIndex: 'status',
        render: status => `${status}`,
        width: '25%'
      },
      {
        title: (<FormattedMessage {...messages.tableColTitleResult} />),
        dataIndex: 'total_rating',
        render: rating => `${rating}`,
        width: '25%'
      },
      {
        className: 'item__date',
        title: (<FormattedMessage {...messages.tableColTitleDate} />),
        dataIndex: 'updatedAt',
        render: data => (
          <span>
            <FormattedDate
              value={data}
              year="numeric"
              month="long"
              day="2-digit"
            />
            <FormattedTime
              className="item_col_time"
              value={data}
            />
          </span>
        ),
        width: '25%'
      },
    ],
    tablePreloader: true
  };

  componentDidMount() {
    const { adminToken, history, match } = this.props;
    if (adminToken) {
      const { status, page } = match.params;

      this.setState({
        gamesQuery: {
          status
        },
        page: Number(page)
      }, () => {
        this.getGames();
      });
    } else {
      history.push('/admin/sign-in');
    }
  }

  getGames() {
    const { gamesQuery, page } = this.state;
    this.toggleTablePreloader(true);
    const {
      actions: {
        togglePreloader, showError
      },
      adminToken, history
    } = this.props;
    fetch(
      `${process.env.REACT_APP_API_URL}/game/${page}?${new URLSearchParams(gamesQuery).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${adminToken}`,
          'Content-Type': 'application/json'
        }
      }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            history.push('/admin/sign-in');
          }
          showError(response.status);
          togglePreloader(false);
          this.toggleTablePreloader(false);
        }
        return response.json();
      })
      .then((res) => {
        this.toggleTablePreloader(false);
        togglePreloader(false);
        const { games, totalItems } = res;
        const processedGames = games.map((item) => {
          const status = item.total_rating > 0 ? 'finished' : 'not finished';
          return {
            ...item,
            status
          };
        });
        this.setState({
          games: processedGames,
          gamesTotal: Number(totalItems)
        });
      });
  }

  handleLogoutClick = () => {
    const { actions: { adminLogout }, history } = this.props;
    adminLogout(history.push);
  };

  handleStatusChange = (event) => {
    this.setState({
      gamesQuery: {
        status: event.target.value
      },
      page: 1
    }, () => {
      this.updateUrl();
      this.getGames();
    });
  };

  handleTableChange = (pagination) => {
    this.setState({
      page: pagination.current
    }, () => {
      this.updateUrl();
      this.getGames();
    });
  };

  toggleTablePreloader(status) {
    this.setState({
      tablePreloader: !!status
    });
  }

  updateUrl() {
    const { history } = this.props;
    const { gamesQuery: { status }, page } = this.state;
    history.push(`/admin/games-list/${status}/${page}`);
  }

  render() {
    const { adminProfile } = this.props;
    const {
      games, gamesTotal, gamesQuery,
      page, tableColumns, tablePreloader
    } = this.state;
    return (
      <AdminGamesListView
        adminProfile={adminProfile}
        games={games}
        gamesTotal={gamesTotal}
        gamesQuery={gamesQuery}
        handleLogoutClick={this.handleLogoutClick}
        handleStatusChange={this.handleStatusChange}
        handleTableChange={this.handleTableChange}
        page={page}
        tableColumns={tableColumns}
        tablePreloader={tablePreloader}
      />
    );
  }
}

AdminGamesList.propTypes = {
  actions: PropTypes.shape({
    adminLogout: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    togglePreloader: PropTypes.func.isRequired
  }).isRequired,
  adminProfile: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  adminToken: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string,
      page: PropTypes.string
    })
  }).isRequired
};

const mapStateToProps = state => ({
  adminProfile: state.adminProfile.profile,
  adminToken: state.adminProfile.token
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...adminProfileActions, ...pageActions }, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminGamesList));
