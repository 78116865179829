/* locale */
export const TOGGLE_LOCALE = 'TOGGLE_LOCALE';
export const DELETE_LOCALE = 'DELETE_LOCALE';

/* page */
export const HIDE_ERROR = 'HIDE_ERROR';
export const TOGGLE_PRELOADER = 'TOGGLE_PRELOADER';
export const SHOW_ERROR = 'SHOW_ERROR';

/* game */
export const RESET_GAME = 'RESET_GAME';
export const SELECT_CHARACTER = 'SELECT_CHARACTER';
export const SET_PLAYER_STATS = 'SET_PLAYER_STATS';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const UPDATE_CHARACTERS = 'UPDATE_CHARACTERS';
export const UPDATE_GAME_STEP = 'UPDATE_GAME_STEP';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_STATS = 'UPDATE_PLAYER_STATS';

/* admin profile */
export const ADMIN_LOGIN_HANDLE = 'ADMIN_LOGIN_HANDLE';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const ADMIN_TOGGLE_PROFILE_LOADING = 'ADMIN_TOGGLE_PROFILE_LOADING';
export const ADMIN_UPDATE_PROFILE = 'ADMIN_UPDATE_PROFILE';
