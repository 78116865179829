import React from 'react';
import PropTypes from 'prop-types';
import GameHeader from './components/GameHeader';
import GameQuestionBlock from './components/GameQuestionBlock';
import GameAnswerBlock from './components/GameAnswerBlock';

function GameView({
  answer, closeAnswer, playerCharacter,
  takeAnswer, stats, step, question,
  userCharacter
}) {
  return (
    <div
      className="App__page App__game"
    >
      {
        playerCharacter
        && stats
        && (
          <GameHeader
            character={playerCharacter}
            stats={stats}
            userCharacter={userCharacter}
          />
        )
      }
      {
        step === 'question'
        && question
        && (
          <GameQuestionBlock
            character={question.from_character}
            sendAnswer={takeAnswer}
            question={question}
            stats={stats}
          />
        )
      }
      {
        step === 'answer'
        && answer
        && (
          <GameAnswerBlock
            closeAnswer={closeAnswer}
            answer={answer}
            stats={stats}
            userCharacter={userCharacter}
          />
        )
      }
    </div>
  );
}

GameView.propTypes = {
  answer: PropTypes.shape({}),
  closeAnswer: PropTypes.func.isRequired,
  playerCharacter: PropTypes.shape({}).isRequired,
  stats: PropTypes.shape({}).isRequired,
  step: PropTypes.string.isRequired,
  takeAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    from_character: PropTypes.shape({})
  }),
  questionCharacter: PropTypes.shape({}),
  userCharacter: PropTypes.bool
};

GameView.defaultProps = {
  answer: {},
  question: null,
  questionCharacter: null,
  userCharacter: false
};

export default GameView;
