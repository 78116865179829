import React from 'react';
import PropTypes from 'prop-types';

function GameStatsListItem({
  stat, statChanges, statImg, statName
}) {
  const progressBarClass = stat > 49
    ? 'App__gameStatsList__item__progressBar__positive'
    : 'App__gameStatsList__item__progressBar__negative';
  return (
    <div className="App__gameStatsList__item">
      {
        statImg
        && (
          <div className="App__gameStatsList__item__icon">
            <img src={statImg} alt={statName} />
          </div>
        )
      }
      <div className="App__gameStatsList__item__name">
        {statName}
      </div>
      <div className={`App__gameStatsList__item__changes ${statChanges > 0 ? 'positive' : 'negative'}`}>
        {statChanges && statChanges}
      </div>
      <div
        className={`App__gameStatsList__item__progressBar ${progressBarClass}`}
      >
        <div
          className="App__gameStatsList__item__progressBar__bar"
          style={{
            width: `${stat}%`
          }}
        />
        <div className="App__gameStatsList__item__progressBar__value">
          {stat}
        </div>
      </div>
    </div>
  );
}

GameStatsListItem.propTypes = {
  stat: PropTypes.number.isRequired,
  statChanges: PropTypes.number,
  statImg: PropTypes.node.isRequired,
  statName: PropTypes.node.isRequired
};

GameStatsListItem.defaultProps = {
  statChanges: null
};

export default GameStatsListItem;
