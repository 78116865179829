import { TOGGLE_LOCALE } from '../constants/actionTypes';
import initialState from '../translates';

function changeLanguage(locale, messages) {
  const locales = Object.keys(messages);
  const nextLocaleIndex = locales.indexOf(locale) + 1;
  return nextLocaleIndex >= locales.length ? locales[0] : locales[nextLocaleIndex];
}

export default function translates(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LOCALE:
      return {
        ...state,
        locale: changeLanguage(state.locale, state.messages)
      };

    default:
      return state;
  }
}
