import {
  Typography
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './AdminSignIn.i18n';
import SignInForm from './components/SignInForm';

export default function AdminSignInView({
  handleSubmit, loading, password, email, logInHandle
}) {
  const { Title } = Typography;

  return (
    <div className="App__page App__signIn">
      <Title>
        <FormattedMessage {...messages.greeting} />
      </Title>
      <div className="App__signIn__form">
        <SignInForm
          handleSubmit={handleSubmit}
          loading={loading}
          password={password}
          email={email}
          logInHandle={logInHandle}
        />
      </div>
    </div>
  );
}

AdminSignInView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  logInHandle: PropTypes.func.isRequired,
};
