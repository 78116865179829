import { RESULT_VIEW as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.descriptionTitle`]: 'Результати',
  [`${idPrefix}.statsSum`]: 'Сума балів',
  [`${idPrefix}.shareResultTitle`]: 'Поділитися результатом',
  [`${idPrefix}.goNewGame`]: 'Нова гра',
};

export default messages;
