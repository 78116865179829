/* layout translates */
import page from './layout/Page/Page.ua.i18n';

/* pages translates */
import home from './pages/Home/Home.uk.i18n';
import startStep from './pages/Rules/Rules.uk.i18n';
import characterChoose from './pages/CharacterChoose/CharacterChoose.uk.i18n';
import results from './pages/Results/Results.uk.i18n';
import adminSignIn from './pages/AdminSignIn/AdminSignIn.uk.i18n';
import adminGamesList from './pages/AdminGamesList/AdminGamesList.uk.i18n';
import errorMessages from './actions/page.uk.i18n';
import characterStats from './global/characterStats.uk.i18n';


export default {
  ...page,
  ...home,
  ...startStep,
  ...characterChoose,
  ...results,
  ...adminSignIn,
  ...adminGamesList,
  ...errorMessages,
  ...characterStats
};
