import { CHARACTER_STATS as idPrefix } from '../../constants/translates';

const messages = {
  [`${idPrefix}.budget`]: 'Фінанси',
  [`${idPrefix}.legality`]: 'Законність',
  [`${idPrefix}.rating`]: 'Популярність',
  [`${idPrefix}.securityDefault`]: 'Впливовість',
  [`${idPrefix}.securityUser`]: 'Приватність',
};

export default messages;
