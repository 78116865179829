import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

function GameQuestionBlock({
  character, sendAnswer, question
}) {
  const answerActions = question.answers
    .sort((a, b) => b.answer.length - a.answer.length)
    .map((item) => {
      return (
        <Button
          type="primary"
          onClick={() => { sendAnswer(item); }}
          key={`questionAnswerAction_${item.id}`}
        >
          {item.answer}
        </Button>
      );
    });

  return (
    <div className="App__gameBlock App__gameBlock__question">
      <div className="App__gameBlock__character__avatar">
        {
          character.image.path
          && (<img src={character.image.path} alt={character.name} />)
        }
      </div>
      <div className="App__gameBlock__content">
        {/* eslint-disable-next-line react/no-danger */}
        <div className="App__gameBlock__text" dangerouslySetInnerHTML={{ __html: question.name }} />
        <div className="App__gameBlock__actions">
          {answerActions}
        </div>
      </div>
    </div>
  );
}

GameQuestionBlock.propTypes = {
  character: PropTypes.shape({
    image: PropTypes.shape({
      path: PropTypes.string
    }),
    name: PropTypes.string
  }).isRequired,
  sendAnswer: PropTypes.func.isRequired,
  stats: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string
      })
    ),
    name: PropTypes.string
  }).isRequired
};

export default GameQuestionBlock;
