import { combineReducers } from 'redux';
import adminProfile from './adminProfile';
import translates from './translates';
import game from './game';
import page from './page';

const rootReducer = combineReducers({
  adminProfile,
  game,
  page,
  translates
});

export default rootReducer;
