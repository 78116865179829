import { defineMessages } from 'react-intl';
import { CHARACTER_CHOOSE_VIEW as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  title: {
    id: `${idPrefix}.title`,
    defaultMessage: 'Choose your character'
  },
  characterUser: {
    id: `${idPrefix}.characterUser`,
    defaultMessage: 'Client'
  },
  characterJournalist: {
    id: `${idPrefix}.characterJournalist`,
    defaultMessage: 'Journalist'
  },
  characterBlogger: {
    id: `${idPrefix}.characterBlogger`,
    defaultMessage: 'Blogger'
  },
  goNextStep: {
    id: `${idPrefix}.goNextStep`,
    defaultMessage: 'Start game'
  },
});

export default messages;
