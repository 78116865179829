import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as translateActions from '../../actions/translate';
import PageView from './PageView';

class Page extends Component {
  render() {
    const {
      children, locale, messages, preloaderStatus
    } = this.props;
    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={messages[locale]}
      >
        <PageView
          preloaderStatus={preloaderStatus}
        >
          {children}
        </PageView>
      </IntlProvider>
    );
  }
}

Page.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.shape({}).isRequired,
  preloaderStatus: PropTypes.bool
};

Page.defaultProps = {
  preloaderStatus: false
};

const mapStateToProps = state => ({
  locale: state.translates.locale,
  messages: state.translates.messages,
  preloaderStatus: state.page.preloaderStatus
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...translateActions }, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
