import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FacebookProvider, Share } from 'react-facebook';
import PropTypes from 'prop-types';
import GameStatsList from '../../elements/GameStatsList/GameStatsList';
import { ReactComponent as ShareIconFB } from '../../../assets/images/gameResults/share-icon-fb.svg';
import characterStatsMessages from '../../translates/global/characterStats.i18n';
import messages from './Results.i18n';


function ResultsView({
  result, startNewGame, stats, statsSum, shareDescription, shareUrl, userCharacter
}) {
  return (
    <div className="App__page App__game-result">
      <div className="App__game-result__description">
        <div className="App__game-result__description__title">
          <FormattedMessage {...messages.descriptionTitle} />
        </div>
        {
          result.security
          && result.security[0]
          && (
            <div className="App__game-result__description__item App__game-result__description__security">
              <p>
                {(
                  userCharacter
                    ? (<FormattedMessage {...characterStatsMessages.securityUser} />)
                    : (<FormattedMessage {...characterStatsMessages.securityDefault} />)
                )}
              </p>
              <p>
                {result.security[0].description}
              </p>
            </div>
          )
        }
        {
          result.rating
          && result.rating[0]
          && (
            <div className="App__game-result__description__item App__game-result__description__rating">
              <p>
                <FormattedMessage {...characterStatsMessages.rating} />
              </p>
              <p>
                {result.rating[0].description}
              </p>
            </div>
          )
        }
        {
          result.legality
          && result.legality[0]
          && (
            <div className="App__game-result__description__item App__game-result__description__legality">
              <p>
                <FormattedMessage {...characterStatsMessages.legality} />
              </p>
              <p>
                {result.legality[0].description}
              </p>
            </div>
          )
        }
        {
          result.budget
          && result.budget[0]
          && (
            <div className="App__game-result__description__item App__game-result__description__budget">
              <p>
                <FormattedMessage {...characterStatsMessages.budget} />
              </p>
              <p>
                {result.budget[0].description}
              </p>
            </div>
          )
        }
      </div>
      <div className="App__game-result__statsSum">
        <FormattedMessage {...messages.statsSum} />
        :
        <span>{statsSum}</span>
      </div>
      <div className="App__game-result__statsList">
        <GameStatsList stats={stats} userCharacter={userCharacter} />
      </div>
      <div className="App__game-result__shareResult">
        <div className="App__game-result__shareResult__title">
          <FormattedMessage {...messages.shareResultTitle} />
          :
        </div>
        <FacebookProvider
          appId="183214849584289"
        >
          <Share
            href={shareUrl}
            quote={shareDescription}
          >
            {({ handleClick, loading }) => (
              <button
                className="App__game-result__shareResult__fb"
                type="button"
                disabled={loading}
                onClick={handleClick}
              >
                <ShareIconFB />
              </button>
            )}
          </Share>
        </FacebookProvider>
      </div>
      <div className="App__toNextStep App__result__actions">
        <Button
          type="primary"
          onClick={startNewGame}
        >
          <FormattedMessage {...messages.goNewGame} />
        </Button>
      </div>
    </div>
  );
}

ResultsView.propTypes = {
  result: PropTypes.shape({
    security: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.node
    })),
    rating: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.node
    })),
    legality: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.node
    })),
    budget: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.node
    }))
  }).isRequired,
  startNewGame: PropTypes.func.isRequired,
  stats: PropTypes.shape({}).isRequired,
  statsSum: PropTypes.number.isRequired,
  shareDescription: PropTypes.node,
  shareUrl: PropTypes.string.isRequired,
  userCharacter: PropTypes.bool
};

ResultsView.defaultProps = {
  shareDescription: '',
  userCharacter: false
};

export default ResultsView;
