import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import RulesView from './RulesView';
import { CHOOSE_CHARACTER, RULES } from '../../constants/gameSteps';
import * as gameActions from '../../actions/game';
import * as pageActions from '../../actions/page';

class Rules extends Component {
  state = {
    nextStepDisabled: true
  };

  componentDidMount() {
    const { actions } = this.props;
    const {
      showError, updateGameStep, updatePlayer
    } = actions;
    fetch(
      `${process.env.REACT_APP_API_URL}/player/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: 'player' })
      }
    )
      .then((response) => {
        if (!response.ok) {
          showError(response.status);
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((resp) => {
        updatePlayer({
          id: resp.playerId
        });
        updateGameStep(RULES);
        this.toggleNextStepButton(false);
      })
      .catch(() => {
      });
  }

  goToCharacterChoose = () => {
    const { actions, history } = this.props;
    const { updateGameStep } = actions;

    updateGameStep(CHOOSE_CHARACTER);
    history.push(`/${CHOOSE_CHARACTER}`);
  };

  toggleNextStepButton(disableStatus) {
    this.setState({
      nextStepDisabled: disableStatus
    });
  }

  render() {
    const { nextStepDisabled } = this.state;

    return (
      <RulesView
        goToCharacterChoose={this.goToCharacterChoose}
        nextStepDisabled={nextStepDisabled}
      />
    );
  }
}

Rules.propTypes = {
  actions: PropTypes.shape({
    showError: PropTypes.func.isRequired,
    togglePreloader: PropTypes.func.isRequired,
    updateGameStep: PropTypes.func.isRequired,
    updatePlayer: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  // playerId: PropTypes.number.isRequired
};


const mapStateToProps = state => ({
  playerId: state.game.player.id
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...pageActions, ...gameActions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rules);
