import { Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';


export default function PageView({ children, preloaderStatus }) {
  return (
    <div className="App">
      <Spin spinning={preloaderStatus}>
        {children}
      </Spin>
    </div>
  );
}

PageView.propTypes = {
  children: PropTypes.element.isRequired,
  preloaderStatus: PropTypes.bool
};

PageView.defaultProps = {
  preloaderStatus: false
};
