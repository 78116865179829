/* layout translates */
import page from './layout/Page/Page.ru.i18n';

/* pages translates */
import home from './pages/Home/Home.ru.i18n';
import startStep from './pages/Rules/Rules.ru.i18n';
import characterChoose from './pages/CharacterChoose/CharacterChoose.ru.i18n';
import results from './pages/Results/Results.ru.i18n';
import adminSignIn from './pages/AdminSignIn/AdminSignIn.ru.i18n';
import adminGamesList from './pages/AdminGamesList/AdminGamesList.ru.i18n';

/* translates */
import characterStats from './global/characterStats.uk.i18n';

export default {
  ...page,
  ...home,
  ...startStep,
  ...characterChoose,
  ...results,
  ...adminSignIn,
  ...adminGamesList,
  ...characterStats
};
