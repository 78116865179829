import { ADMIN_SIGN_IN as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.greeting`]: 'Вхід до адмін панелі',
  [`${idPrefix}.formEmailLabel`]: 'Введіть свій email',
  [`${idPrefix}.formPasswordLabel`]: 'Введіть свій пароль',
  [`${idPrefix}.formSubmitButton`]: 'Увійти'
};

export default messages;
