import { defineMessages } from 'react-intl';
import { ADMIN_SIGN_IN as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  greeting: {
    id: `${idPrefix}.greeting`,
    defaultMessage: 'Enter to admin panel'
  },
  formEmailLabel: {
    id: `${idPrefix}.formEmailLabel`,
    defaultMessage: 'Enter your email'
  },
  formPasswordLabel: {
    id: `${idPrefix}.formPasswordLabel`,
    defaultMessage: 'Enter your password'
  },
  formSubmitButton: {
    id: `${idPrefix}.formSubmitButton`,
    defaultMessage: 'Enter'
  }
});

export default messages;
