import {
  RESET_GAME, SELECT_CHARACTER, SET_PLAYER_STATS, SET_QUESTIONS, UPDATE_CHARACTERS,
  UPDATE_GAME_STEP, UPDATE_PLAYER, UPDATE_PLAYER_STATS
} from '../constants/actionTypes';
import { START, CHOOSE_CHARACTER } from '../constants/gameSteps';

function initialState() {
  return {
    characters: {
      main: [],
      support: [],
      selected: {}
    },
    player: {
      id: 0,
      name: ''
    },
    step: START,
    stats: {},
    questions: []
  };
}

export default function game(state = initialState(), action) {
  switch (action.type) {
    case RESET_GAME:
      return {
        step: CHOOSE_CHARACTER,
        stats: {}
      };

    case SELECT_CHARACTER:
      return {
        ...state,
        characters: {
          ...state.characters,
          selected: action.selected
        }
      };

    case SET_PLAYER_STATS:
      return {
        ...state,
        stats: action.stats
      };

    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.questions
      };

    case UPDATE_CHARACTERS:
      return {
        ...state,
        characters: {
          ...state.characters,
          main: action.characters.main,
          support: action.characters.support
        }
      };

    case UPDATE_GAME_STEP:
      return {
        ...state,
        step: action.step
      };

    case UPDATE_PLAYER_STATS:
      return {
        ...state,
        stats: action.stats
      };

    case UPDATE_PLAYER:
      return {
        ...state,
        player: {
          ...state.player,
          ...action.player
        }
      };

    default:
      return state;
  }
}
