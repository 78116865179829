import {
  Button, Typography
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import messages from './Rules.i18n';
import USAIDLogo from '../../../assets/images/pages/home/USAID-logo.svg';
import InternewsLogo from '../../../assets/images/pages/home/internews-logo.svg';
import CedemLogo from '../../../assets/images/pages/home/cedem-logo.png';

function RulesView({ goToCharacterChoose, nextStepDisabled }) {
  const { Title } = Typography;

  return (
    <div className="App__page App__rules">
      <div className="App__rules__logoList">
        <div className="App__rules__logoList__item">
          <img src={USAIDLogo} alt="USAID logo" />
        </div>
        <div className="App__rules__logoList__item">
          <img src={InternewsLogo} alt="Internews logo" />
        </div>
        <div className="App__rules__logoList__item">
          <img src={CedemLogo} alt="Cedem logo" />
        </div>
      </div>
      <Title>
        <FormattedMessage {...messages.pageTitle} />
      </Title>
      <div className="App__page__textContent">
        <FormattedHTMLMessage {...messages.pageText} />
      </div>
      <div className="App__rules__notes">
        <FormattedHTMLMessage {...messages.pageNotes} />
      </div>
      <div className="App__toNextStep">
        <Button disabled={nextStepDisabled} type="primary" onClick={goToCharacterChoose}>
          <FormattedMessage {...messages.goNextStep} />
        </Button>
      </div>
    </div>
  );
}

export default RulesView;

RulesView.propTypes = {
  goToCharacterChoose: PropTypes.func.isRequired,
  nextStepDisabled: PropTypes.bool.isRequired
};
