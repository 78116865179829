import {
  ADMIN_LOGIN_HANDLE,
  ADMIN_LOGOUT,
  ADMIN_TOGGLE_PROFILE_LOADING,
  ADMIN_UPDATE_PROFILE
} from '../constants/actionTypes';

export function adminUpdateProfile(user) {
  const { email, token } = user;
  const userData = {
    email
  };
  localStorage.setItem('profile', userData ? JSON.stringify(userData) : null);
  localStorage.setItem('token', token || null);
  return {
    type: ADMIN_UPDATE_PROFILE,
    profile: userData || null,
    token: token || null
  };
}

export function adminLogout(historyPush) {
  localStorage.removeItem('token');
  localStorage.removeItem('profile');
  historyPush('/admin/sign-in');
  return { type: ADMIN_LOGOUT };
}

export function adminLogInHandle(value) {
  return { type: ADMIN_LOGIN_HANDLE, value };
}

export function adminToggleProfileLoading() {
  return { type: ADMIN_TOGGLE_PROFILE_LOADING };
}
