import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ResultsView from './ResultsView';
import * as gameActions from '../../actions/game';
import * as pageActions from '../../actions/page';
import { CHOOSE_CHARACTER, START } from '../../constants/gameSteps';
import messages from './Results.i18n';

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: {},
      statsSum: 0
    };
  }

  componentDidMount() {
    const {
      actions, history, playerId, stats
    } = this.props;
    const { togglePreloader, showError, updateGameStep } = actions;

    if (!playerId) {
      updateGameStep(START);
      history.push('/');
    } else if (!stats.budget) {
      updateGameStep(CHOOSE_CHARACTER);
      history.push(`/${CHOOSE_CHARACTER}`);
    } else {
      const statsSum = stats.budget + stats.legality + stats.rating + stats.security;
      this.setState({
        statsSum
      });

      fetch(
        `${process.env.REACT_APP_API_URL}/game/${playerId}/result`,
        {
          method: 'GET'
        }
      ).then((response) => {
        if (!response.ok) {
          showError(response.status);
          togglePreloader(false);
          throw Error(response.statusText);
        }
        return response.json();
      })
        .then((data) => {
          const { gameConclusions } = data;
          this.setState({
            result: gameConclusions
          });
        })
        .catch(() => {});


      togglePreloader(false);
    }
  }

  startNewGame = () => {
    const {
      actions: {
        showError, togglePreloader, updateGameStep, updatePlayer
      },
      history
    } = this.props;

    togglePreloader(true);

    fetch(
      `${process.env.REACT_APP_API_URL}/player/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: 'player' })
      }
    )
      .then((response) => {
        if (!response.ok) {
          showError(response.status);
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((resp) => {
        updatePlayer({
          id: resp.playerId
        });

        updateGameStep(CHOOSE_CHARACTER);
        history.push(`/${CHOOSE_CHARACTER}`);

        this.toggleNextStepButton(false);
      })
      .catch(() => {
      });

    updateGameStep(CHOOSE_CHARACTER);
    history.push(`/${CHOOSE_CHARACTER}`);
  };

  randomResultDescription(results) {
    const keys = Object.keys(results);
    // eslint-disable-next-line no-bitwise
    return results[keys[keys.length * Math.random() << 0]][0].description;
  }

  render() {
    const { intl, playerCharacter, stats } = this.props;
    const { result, statsSum } = this.state;

    let randomResultDescription = '';
    if (Object.keys(result).length) {
      randomResultDescription = this.randomResultDescription(result);
    }
    const shareDescription = `${randomResultDescription}\n${intl.formatMessage(messages.statsSum)}: ${statsSum}`;

    let shareUrl = '';
    if (window.location.hostname === 'localhost') {
      shareUrl = 'http://176.114.9.178';
    } else {
      const { protocol } = window.location;
      const slashes = protocol.concat('//');
      shareUrl = slashes.concat(window.location.hostname);
    }

    const userCharacter = playerCharacter.id === 1;

    return (
      <ResultsView
        result={result}
        startNewGame={this.startNewGame}
        stats={stats}
        statsSum={statsSum}
        shareDescription={shareDescription}
        shareUrl={shareUrl}
        userCharacter={userCharacter}
      />
    );
  }
}

Results.propTypes = {
  actions: PropTypes.shape({
    selectCharacter: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    togglePreloader: PropTypes.func.isRequired,
    updateGameStep: PropTypes.func.isRequired,
    updatePlayer: PropTypes.func.isRequired,
    updatePlayerStats: PropTypes.func.isRequired
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  playerCharacter: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  playerId: PropTypes.number.isRequired,
  stats: PropTypes.shape({
    budget: PropTypes.number,
    legality: PropTypes.number,
    rating: PropTypes.number,
    security: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  playerCharacter: state.game.characters.selected,
  playerId: state.game.player.id,
  stats: state.game.stats
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...gameActions, ...pageActions }, dispatch),
});

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(Results));
