export const PAGE = 'page';
export const START_VIEW = 'startView';
export const CHARACTER_CHOOSE_VIEW = 'characterChooseView';
export const GAME_VIEW = 'gameView';
export const RESULT_VIEW = 'resultView';
export const HOME = 'home';
export const ERROR_MESSAGES = 'errorMessages';
export const FORM_VALIDATION_RULES = 'formValidationRules';

export const ADMIN_SIGN_IN = 'adminSignIn';
export const ADMIN_GAMES_LIST = 'adminGamesList';

export const CHARACTER_STATS = 'characterStats';
