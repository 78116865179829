import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import GameStatsMiniListItem from './GameStatsMiniListItem';
import FinanceIcon from '../../../assets/images/icons/budget.svg';
import LegalityIcon from '../../../assets/images/icons/legality.svg';
import PopularityIcon from '../../../assets/images/icons/rating.svg';
import SecurityIcon from '../../../assets/images/icons/security.svg';
import characterStatsMessages from '../../translates/global/characterStats.i18n';

function GameStatsMiniList({ className, stats, userCharacter }) {
  return (
    <div className={`App__gameStatsMiniList ${className}`}>
      {
        stats.security
        && (
          <GameStatsMiniListItem
            stat={stats.security}
            statName={(
              userCharacter
                ? (<FormattedMessage {...characterStatsMessages.securityUser} />)
                : (<FormattedMessage {...characterStatsMessages.securityDefault} />)
            )}
            statImg={SecurityIcon}
          />
        )
      }
      {
        stats.rating
        && (
          <GameStatsMiniListItem
            stat={stats.rating}
            statName={(<FormattedMessage {...characterStatsMessages.rating} />)}
            statImg={PopularityIcon}
          />
        )
      }
      {
        stats.legality
        && (
          <GameStatsMiniListItem
            stat={stats.legality}
            statName={(<FormattedMessage {...characterStatsMessages.legality} />)}
            statImg={LegalityIcon}
          />
        )
      }
      {
        stats.budget
        && (
          <GameStatsMiniListItem
            stat={stats.budget}
            statName={(<FormattedMessage {...characterStatsMessages.budget} />)}
            statImg={FinanceIcon}
          />
        )
      }
    </div>
  );
}

GameStatsMiniList.propTypes = {
  className: PropTypes.string,
  stats: PropTypes.shape({
    security: PropTypes.number,
    rating: PropTypes.number,
    legality: PropTypes.number,
    budget: PropTypes.number
  }).isRequired,
  userCharacter: PropTypes.bool
};

GameStatsMiniList.defaultProps = {
  className: '',
  userCharacter: false
};

export default GameStatsMiniList;
