import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';
import messages from '../AdminSignIn.i18n';
import formValidationRules from '../../../formValidationRules/formValidationRules';

function SignInForm({
  form: { getFieldDecorator, validateFields },
  handleSubmit, intl, loading, password, email, logInHandle
}) {
  return (
    <Form onSubmit={e => handleSubmit(e, validateFields)}>
      <Form.Item>
        {getFieldDecorator('email', {
          rules: formValidationRules.email,
          initialValue: email,
        })(
          <Input
            placeholder={intl.formatMessage({ ...messages.formEmailLabel })}
            onChange={e => logInHandle({ email: e.target.value.toLowerCase() })}
            autoComplete="on"
          />
        )}
      </Form.Item>
      <Form.Item
        hasFeedback
      >
        {getFieldDecorator('password', {
          rules: formValidationRules.signInPassword,
          initialValue: password,
        })(
          <Input.Password
            placeholder={intl.formatMessage({ ...messages.formPasswordLabel })}
            onChange={e => logInHandle({ password: e.target.value })}
            autoComplete="on"
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          <FormattedMessage {...messages.formSubmitButton} />
        </Button>
      </Form.Item>
    </Form>
  );
}

SignInForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  logInHandle: PropTypes.func.isRequired,
};

export default Form.create({ name: 'signIn' })(injectIntl(SignInForm));
