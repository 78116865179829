import { START_VIEW as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.pageTitle`]: 'Вижити в Інтернеті – місія (не)здійсненна',
  [`${idPrefix}.pageText`]: `
    <p>
      У сучасному світі лайків і репостів ти починаєш свій день з соціальних мереж й Інтернету.
      Але чи кожен розуміє політики платформ і сайтів?
    </p>
    <ul>
        <li>
            Обери свого персонажа: користувач, блогер або журналістка.
        </li>
        <li>
            Дай відповіді на 10 питань і дізнайся, чи зможеш ти вижити в Інтернеті, нічого не порушивши.
        </li>
        <li>
            Пошир результат у Фейсбуці та порадь гру друзям.
        </li>
    </ul>
    <p>
      Кожна твоя дія впливатиме на чотири показники:
      фінансовий стан (уникай штрафів!),
      популярність (репости, підписки, лайки!),
      законність (поліція уже виїжджає!),
      приватність (для пересічних користувачів)
      або впливовість (для блогерів та журналістів). 
    </p>
    <p>
        Для кожного героя своя гра,
        свої перешкоди і свої правильні відповіді!
        Без ставок на спорт, реєстрації та смс.
        Лише про нудні закони в інтерактивному форматі.
        Будь обережним – заблокований акаунт потребує особливих навичок для розблокування 😉
    </p>
    <p>
        Місія (не)здійсненна – вижити в Інтернеті і нічого не порушити!
    </p>
  `,
  [`${idPrefix}.pageNotes`]: `
    Виготовлення гри "Вижити в Інтернеті - місія (не)можлива"
    стало можливим завдяки підтримці американського народу,
    що була надана через проект USAID «Медійна програма в Україні»,
    який виконується міжнародною організацією Internews.
    Зміст матеріалів є виключно відповідальністю Центру демократії та верховенства права
    та необов’язково відображає точку зору USAID, уряду США та Internews.
  `,
  [`${idPrefix}.goNextStep`]: 'Почати гру'
};

export default messages;
