import { RESULT_VIEW as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.descriptionTitle`]: 'Результати',
  [`${idPrefix}.statsSum`]: 'Сума балов',
  [`${idPrefix}.shareResultTitle`]: 'Поделиться результатом',
  [`${idPrefix}.goNewGame`]: 'Новая гра',
};

export default messages;
