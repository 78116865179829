import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import CharacterChooseView from './CharacterChooseView';
import * as gameActions from '../../actions/game';
import * as pageActions from '../../actions/page';
import { GAME_IN_PROGRESS, START } from '../../constants/gameSteps';

class CharacterChoose extends Component {
  componentDidMount() {
    const { actions, history, playerId } = this.props;
    const {
      togglePreloader, showError, updateCharacters, updateGameStep
    } = actions;

    if (playerId) {
      togglePreloader(true);

      fetch(
        `${process.env.REACT_APP_API_URL}/character`,
        {
          method: 'GET'
        }
      )
        .then((response) => {
          if (!response.ok) {
            showError(response.status);
            togglePreloader(false);
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          updateCharacters(data);
          togglePreloader(false);
        })
        .catch(() => {});
    } else {
      updateGameStep(START);
      history.push('/');
    }
  }

  characterSelect = (characterId) => {
    const { actions } = this.props;
    const { selectCharacter } = actions;
    selectCharacter(characterId);
  };

  startGame = () => {
    const {
      actions, history, playerId, selectedCharacter
    } = this.props;
    const {
      togglePreloader, setPlayerStats, setQuestions, showError, updateGameStep
    } = actions;
    const sentValues = {
      characterId: selectedCharacter.id,
      playerId
    };
    togglePreloader(true);

    fetch(
      `${process.env.REACT_APP_API_URL}/player/set/character`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(sentValues)
      }
    )
      .then((response) => {
        if (!response.ok) {
          showError(response.status);
          togglePreloader(false);
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((resp) => {
        const { newRating, questions } = resp;
        togglePreloader(false);
        setQuestions(questions);
        setPlayerStats({
          budget: newRating.budget,
          legality: newRating.legality,
          rating: newRating.rating,
          security: newRating.security,
        });
        updateGameStep(GAME_IN_PROGRESS);
        history.push(`/${GAME_IN_PROGRESS}`);
      })
      .catch(() => {});
  };

  render() {
    const { mainCharacters, selectedCharacter } = this.props;

    return (
      <CharacterChooseView
        characters={mainCharacters}
        characterSelect={this.characterSelect}
        selectedCharacter={selectedCharacter}
        goNextStep={this.startGame}
      />
    );
  }
}

CharacterChoose.propTypes = {
  actions: PropTypes.shape({
    showError: PropTypes.func.isRequired,
    togglePreloader: PropTypes.func.isRequired,
    selectCharacter: PropTypes.func.isRequired,
    setPlayerStats: PropTypes.func.isRequired,
    setQuestions: PropTypes.func.isRequired,
    updateCharacters: PropTypes.func.isRequired,
    updateGameStep: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  mainCharacters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  playerId: PropTypes.number.isRequired,
  selectedCharacter: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
};

const mapStateToProps = state => ({
  mainCharacters: state.game.characters.main,
  playerId: state.game.player.id,
  selectedCharacter: state.game.characters.selected
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...gameActions, ...pageActions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacterChoose);
