import { defineMessages } from 'react-intl';
import { FORM_VALIDATION_RULES as idPrefix } from '../constants/translates';

const messages = defineMessages({
  emailNotEmail: {
    id: `${idPrefix}.emailNotEmail`,
    defaultMessage: 'The input is not valid E-mail!'
  },
  emailRequired: {
    id: `${idPrefix}.emailRequired`,
    defaultMessage: 'Please enter your E-mail!'
  },
  firstNameRequired: {
    id: `${idPrefix}.firstNameRequired`,
    defaultMessage: 'Please enter your first name!'
  },
  lastNameRequired: {
    id: `${idPrefix}.lastNameRequired`,
    defaultMessage: 'Please enter your last name!'
  },
  passwordRequired: {
    id: `${idPrefix}.passwordRequired`,
    defaultMessage: 'Please enter your password!\n'
  },
  passwordShort: {
    id: `${idPrefix}.passwordShort`,
    defaultMessage: 'Password must be eight characters or longer\n'
  },
  passwordSpecialCharacter: {
    id: `${idPrefix}.passwordSpecialCharacter`,
    defaultMessage: 'Password must contain at least one special character\n'
  },
  passwordNumericCharacter: {
    id: `${idPrefix}.passwordNumericCharacter`,
    defaultMessage: 'Password must contain at least 1 numeric character\n'
  },
  passwordUppercaseCharacter: {
    id: `${idPrefix}.passwordUppercaseCharacter`,
    defaultMessage: 'Password must contain at least 1 uppercase alphabetical character\n'
  },
  passwordLowercaseCharacter: {
    id: `${idPrefix}.passwordLowercaseCharacter`,
    defaultMessage: 'Password must contain at least 1 lowercase alphabetical character\n'
  },
  repeatPasswordRequired: {
    id: `${idPrefix}.repeatPasswordRequired`,
    defaultMessage: 'Please enter your password!'
  },
  signInPasswordRequired: {
    id: `${idPrefix}.signInPasswordRequired`,
    defaultMessage: 'Please enter your password!'
  },
  phoneRule: {
    id: `${idPrefix}.phoneRule`,
    defaultMessage: 'Phone must contain + and 11 digits'
  },
});

export default messages;
