import { defineMessages } from 'react-intl';
import { ADMIN_GAMES_LIST as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  headerMenuLogout: {
    id: `${idPrefix}.headerMenuLogout`,
    defaultMessage: 'Logout'
  },
  titleStatusAll: {
    id: `${idPrefix}.titleStatusAll`,
    defaultMessage: 'All games'
  },
  titleStatusFinished: {
    id: `${idPrefix}.titleStatusFinished`,
    defaultMessage: 'Finished games'
  },
  tableColCharacterNotSelected: {
    id: `${idPrefix}.tableColCharacterNotSelected`,
    defaultMessage: 'Not selected'
  },
  tableColTitleCharacter: {
    id: `${idPrefix}.tableColTitleCharacter`,
    defaultMessage: 'Character'
  },
  tableColTitleStatus: {
    id: `${idPrefix}.tableColTitleStatus`,
    defaultMessage: 'Status'
  },
  tableColTitleResult: {
    id: `${idPrefix}.tableColTitleResult`,
    defaultMessage: 'Result'
  },
  tableColTitleDate: {
    id: `${idPrefix}.tableColTitleDate`,
    defaultMessage: 'Date'
  },
  tableColStatusFinished: {
    id: `${idPrefix}.tableColStatusFinished`,
    defaultMessage: 'Finished'
  },
  tableColStatusNotFinished: {
    id: `${idPrefix}.tableColStatusNotFinished`,
    defaultMessage: 'Not finished'
  }
});

export default messages;
