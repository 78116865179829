import { Button, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './CharacterChoose.i18n';
import CharactersListItem from './components/CharactersListItem';

export default function CharacterChooseView({
  characters, characterSelect, goNextStep, selectedCharacter
}) {
  const { Title } = Typography;
  const charactersList = characters.map(
    character => (
      <CharactersListItem
        character={character}
        key={`character_${character.id}`}
        characterSelect={characterSelect}
        selectedCharacter={selectedCharacter}
      />
    )
  );
  return (
    <div className="App__page App__charactersChoose">
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>
      {
        characters.length
        && (
          <div className="App__charactersChoose__list">
            {charactersList}
          </div>
        )
      }
      <div className="App__toNextStep">
        <Button
          type="primary"
          disabled={!selectedCharacter.id}
          onClick={goNextStep}
        >
          <FormattedMessage {...messages.goNextStep} />
        </Button>
      </div>
    </div>
  );
}

CharacterChooseView.propTypes = {
  characters: PropTypes.arrayOf(PropTypes.shape({

  })),
  characterSelect: PropTypes.func.isRequired,
  goNextStep: PropTypes.func.isRequired,
  selectedCharacter: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

CharacterChooseView.defaultProps = {
  characters: []
};
