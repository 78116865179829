import { SHOW_ERROR, TOGGLE_PRELOADER } from '../constants/actionTypes';

function initialState() {
  return {
    error: {
      message: '',
      status: false
    },
    preloaderStatus: false
  };
}

export default function page(state = initialState(), action) {
  switch (action.type) {
    case TOGGLE_PRELOADER:
      return {
        ...state,
        preloaderStatus: action.preloaderStatus
      };
    case SHOW_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
