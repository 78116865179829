import { defineMessages } from 'react-intl';
import { START_VIEW as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  pageTitle: {
    id: `${idPrefix}.pageTitle`,
    defaultMessage: 'Game rules'
  },
  pageText: {
    id: `${idPrefix}.pageText`,
    defaultMessage: 'Game rules text'
  },
  pageNotes: {
    id: `${idPrefix}.pageNotes`,
    defaultMessage: 'Game notes text'
  },
  goNextStep: {
    id: `${idPrefix}.goNextStep`,
    defaultMessage: 'Go to character choose'
  },
});

export default messages;
