import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './formValidationRules.i18n';

export default {
  email: [
    {
      type: 'email',
      message: (<FormattedMessage {...messages.emailNotEmail} />)
    },
    {
      required: true,
      message: (<FormattedMessage {...messages.emailRequired} />)
    }
  ],
  firstName: [
    {
      required: true,
      message: (<FormattedMessage {...messages.firstNameRequired} />)
    },
  ],
  lastName: [
    {
      required: true,
      message: (<FormattedMessage {...messages.lastNameRequired} />)
    },
  ],
  password: [
    {
      required: true,
      message: (<FormattedMessage {...messages.passwordRequired} />)
    },
    {
      pattern: '^(?=.{8,})',
      message: (<FormattedMessage {...messages.passwordShort} />)
    },
    {
      pattern: '^(?=.*[!@#\\$%\\^&\\*])',
      message: (<FormattedMessage {...messages.passwordSpecialCharacter} />)
    },
    {
      pattern: '^(?=.*[0-9])',
      message: (<FormattedMessage {...messages.passwordNumericCharacter} />)
    },
    {
      pattern: '^(?=.*[A-Z])',
      message: (<FormattedMessage {...messages.passwordUppercaseCharacter} />)
    },
    {
      pattern: '^(?=.*[a-z])',
      message: (<FormattedMessage {...messages.passwordLowercaseCharacter} />)
    }
  ],
  repeatPassword: [
    {
      required: true,
      message: (<FormattedMessage {...messages.repeatPasswordRequired} />)
    }
  ],
  signInPassword: [
    {
      required: true,
      message: (<FormattedMessage {...messages.signInPasswordRequired} />)
    }
  ],
  phone: [
    {
      pattern: '^[+]{1}\\d{11}$',
      message: (<FormattedMessage {...messages.phoneRule} />)
    }
  ]
};
