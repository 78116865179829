import { addLocaleData } from 'react-intl';
import intlEN from 'react-intl/locale-data/en';
import intlRU from 'react-intl/locale-data/ru';
import intlUK from 'react-intl/locale-data/uk';

import ru from './ru';
import uk from './uk';

addLocaleData(
  [
    ...intlEN,
    ...intlRU,
    ...intlUK
  ]
);

export default {
  locale: 'uk',
  messages: {
    en: {},
    ru,
    uk
  }
};
