import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import GameView from './GameView';
import * as gameActions from '../../actions/game';
import { CHOOSE_CHARACTER, GAME_IS_OVER, START } from '../../constants/gameSteps';
import * as pageActions from '../../actions/page';

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: {},
      step: 'question',
      questionIndex: 0
    };
  }

  componentDidMount() {
    const {
      actions, history, playerId, questions
    } = this.props;
    const { togglePreloader, updateGameStep } = actions;

    if (!playerId) {
      updateGameStep(START);
      history.push('/');
    }

    if (!questions.length) {
      updateGameStep(CHOOSE_CHARACTER);
      history.push(`/${CHOOSE_CHARACTER}`);
    }

    if (playerId && questions.length) {
      togglePreloader(false);
    }
  }

  closeAnswer = () => {
    const { questions } = this.props;
    const { questionIndex } = this.state;
    if (questionIndex < questions.length) {
      this.setState({
        answer: {},
        step: 'question'
      });
    } else {
      this.goToResults();
    }
  };

  getAnswer = (answer) => {
    const { actions, playerId, questions } = this.props;
    const { questionIndex } = this.state;
    const { togglePreloader, showError, updatePlayerStats } = actions;
    const question = questions[questionIndex];
    const sentValues = {
      answerId: answer.id,
      questionId: question.id
    };

    if (questionIndex === (questions.length - 1)) {
      sentValues.isFinished = true;
    }

    togglePreloader(true);

    fetch(
      `${process.env.REACT_APP_API_URL}/player/${playerId}/update/rating`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(sentValues)
      }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 500) {
            this.goToResults();
            return false;
          }
          showError(response.status);
          togglePreloader(false);
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        updatePlayerStats({
          budget: data.budget,
          legality: data.legality,
          rating: data.rating,
          security: data.security,
        });

        return fetch(
          `${process.env.REACT_APP_API_URL}/answer/${answer.id}`,
          {
            method: 'GET'
          }
        )
          .then((response) => {
            if (!response.ok) {
              showError(response.status);
              togglePreloader(false);
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then((answerData) => {
            this.setState({
              answer: answerData,
              step: 'answer',
              questionIndex: questionIndex + 1
            });
            togglePreloader(false);
          });
      })
      .catch(() => {});
  };

  goToResults() {
    const { actions, history } = this.props;
    const { togglePreloader, updateGameStep } = actions;

    togglePreloader(true);
    updateGameStep(GAME_IS_OVER);
    history.push(`/${GAME_IS_OVER}`);
  }

  render() {
    const {
      playerCharacter, stats, questions
    } = this.props;
    const { answer, step, questionIndex } = this.state;
    const question = questions[questionIndex];

    const userCharacter = playerCharacter.id === 1;

    return (
      <GameView
        answer={answer}
        closeAnswer={this.closeAnswer}
        playerCharacter={playerCharacter}
        stats={stats}
        step={step}
        takeAnswer={this.getAnswer}
        question={question}
        userCharacter={userCharacter}
      />
    );
  }
}

Game.propTypes = {
  actions: PropTypes.shape({
    selectCharacter: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    togglePreloader: PropTypes.func.isRequired,
    updateGameStep: PropTypes.func.isRequired,
    updatePlayerStats: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  playerCharacter: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.shape({
      path: PropTypes.string
    })
  }).isRequired,
  playerId: PropTypes.number.isRequired,
  stats: PropTypes.shape({
    budget: PropTypes.number,
    legality: PropTypes.number,
    rating: PropTypes.number,
    security: PropTypes.number,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    from_character_id: PropTypes.number
  }))
};

Game.defaultProps = {
  questions: []
};

const mapStateToProps = state => ({
  playerId: state.game.player.id,
  playerCharacter: state.game.characters.selected,
  stats: state.game.stats,
  supportCharacters: state.game.characters.support,
  questions: state.game.questions
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...gameActions, ...pageActions }, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Game);
