import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import GameStatsList from '../../../elements/GameStatsList/GameStatsList';

function GameAnswerBlock({
  answer, closeAnswer, stats,
  userCharacter
}) {
  const { rating: statsChanges } = answer;
  const answerStats = {
    security: statsChanges.security ? stats.security : null,
    rating: statsChanges.rating ? stats.rating : null,
    legality: statsChanges.legality ? stats.legality : null,
    budget: statsChanges.budget ? stats.budget : null
  };

  return (
    <div className="App__gameBlock App__gameBlock__answer">
      <div className="App__gameBlock__content">
        {/* eslint-disable-next-line react/no-danger */}
        <div className="App__gameBlock__text" dangerouslySetInnerHTML={{ __html: answer.description }} />
        <GameStatsList
          stats={answerStats}
          ratingChanges={answer.rating}
          userCharacter={userCharacter}
        />
        <div className="App__gameBlock__actions">
          <Button
            type="primary"
            onClick={closeAnswer}
          >
            Продовжити
          </Button>
        </div>
      </div>
    </div>
  );
}

GameAnswerBlock.propTypes = {
  answer: PropTypes.shape({
    description: PropTypes.string,
    rating: PropTypes.shape({}).isRequired
  }).isRequired,
  closeAnswer: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    security: PropTypes.number,
    rating: PropTypes.number,
    legality: PropTypes.number,
    budget: PropTypes.number
  }).isRequired,
  userCharacter: PropTypes.bool
};

GameAnswerBlock.defaultProps = {
  userCharacter: false
};

export default GameAnswerBlock;
