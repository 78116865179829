import { START_VIEW as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.pageTitle`]: 'Правила игри',
  [`${idPrefix}.pageText`]: `
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      In laoreet nibh in ipsum aliquet viverra.
      Praesent quis enim eu sapien congue suscipit a vitae diam. 
      Duis iaculis vulputate pharetra.
      Phasellus aliquet mauris non maximus condimentum.
      Vivamus egestas diam tortor, laoreet venenatis felis vulputate quis.
      Nulla id cursus enim, feugiat posuere diam.
      Mauris euismod semper diam.
      Sed sagittis facilisis sem ac fermentum.
      Vivamus porttitor, justo eu tempus consectetur, ipsum justo facilisis nibh,
      laoreet eleifend nisi mauris nec lacus.
      Cras vitae tortor vel turpis mollis pretium.
      Mauris orci lorem, consectetur ac neque at,
      posuere volutpat tellus.
      Donec ut cursus arcu.
      Proin ante ex, pulvinar eu nisi quis, maximus efficitur nibh.
      Suspendisse nec ullamcorper nunc.
      Proin eget placerat mi, id vulputate augue.
    </p>
  `,
  [`${idPrefix}.goNextStep`]: 'Начать игру'
};

export default messages;
