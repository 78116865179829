import { defineMessages } from 'react-intl';
import { RESULT_VIEW as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  descriptionTitle: {
    id: `${idPrefix}.descriptionTitle`,
    defaultMessage: 'Results'
  },
  statsSum: {
    id: `${idPrefix}.statsSum`,
    defaultMessage: 'Score'
  },
  shareResultTitle: {
    id: `${idPrefix}.shareResultTitle`,
    defaultMessage: 'Share core'
  },
  goNewGame: {
    id: `${idPrefix}.goNewGame`,
    defaultMessage: 'New game'
  },
});

export default messages;
