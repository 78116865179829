import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import AdminSignInView from './AdminSignInView';
import * as adminProfileActions from '../../actions/adminProfile';
import * as pageActions from '../../actions/page';

class AdminSignIn extends Component {
  componentWillUnmount() {
    const {
      actions: {
        adminLogInHandle: logInHandle,
        togglePreloader
      },
      adminProfile, adminToken, email, history, password
    } = this.props;
    if (adminProfile.email && adminToken) {
      togglePreloader(true);
      history.push('/admin/games-list/finished/1');
    } else if (email !== '' || password !== '') {
      logInHandle({ email: '', password: '' });
    }
  }

  handleSubmit = (e, validateFields) => {
    e.preventDefault();
    validateFields((error) => {
      const {
        email,
        password,
        actions: {
          adminToggleProfileLoading: toggleProfileLoading,
          showError
        }
      } = this.props;
      if (!error) {
        toggleProfileLoading();
        this.sendData({ email, password })
          .then(this.saveUser)
          .catch(() => {
            toggleProfileLoading();
            showError();
          });
      }
    });
  };

  saveUser = (res) => {
    const {
      actions: {
        adminUpdateProfile: updateProfile,
        adminLogInHandle: logInHandle,
        adminToggleProfileLoading: toggleProfileLoading,
        togglePreloader
      },
      history
    } = this.props;
    toggleProfileLoading();
    if (res.user) {
      togglePreloader(true);
      updateProfile(res.user);
      logInHandle({ email: '', password: '' });
      history.push('/admin/games-list/finished/1');
    }
  };

  sendData(data) {
    const {
      actions: {
        showError
      }
    } = this.props;
    return fetch(
      `${process.env.REACT_APP_API_URL}/users/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user: data })
      }
    )
      .then((response) => {
        if (!response.ok) {
          showError(response.status);
          throw Error(response.statusText);
        }
        return response.json();
      });
  }

  render() {
    const {
      actions: {
        adminLogInHandle: logInHandle
      },
      adminProfileLoading,
      password,
      email
    } = this.props;
    return (
      <AdminSignInView
        handleSubmit={this.handleSubmit}
        loading={adminProfileLoading}
        password={password}
        email={email}
        logInHandle={logInHandle}
      />
    );
  }
}

AdminSignIn.propTypes = {
  actions: PropTypes.shape({
    adminUpdateProfile: PropTypes.func.isRequired,
    adminLogout: PropTypes.func.isRequired,
    adminLogInHandle: PropTypes.func.isRequired,
    adminToggleProfileLoading: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    togglePreloader: PropTypes.func.isRequired,
  }).isRequired,
  adminProfile: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  adminProfileLoading: PropTypes.bool.isRequired,
  adminToken: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

AdminSignIn.defaultProps = {
  adminToken: ''
};

const mapStateToProps = state => ({
  email: state.adminProfile.email,
  adminProfile: state.adminProfile.profile,
  adminProfileLoading: state.adminProfile.loading,
  adminToken: state.adminProfile.token,
  password: state.adminProfile.password
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...adminProfileActions, ...pageActions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSignIn);
