import {
  ADMIN_LOGIN_HANDLE,
  ADMIN_LOGOUT,
  ADMIN_TOGGLE_PROFILE_LOADING,
  ADMIN_UPDATE_PROFILE
} from '../constants/actionTypes';

function initialState() {
  return {
    profile: {
      email: '',
      password: '',
      ...JSON.parse(localStorage.getItem('profile'))
    },
    token: localStorage.getItem('token'),
    email: '',
    password: '',
    loading: false
  };
}


export default function adminProfile(state = initialState(), action) {
  switch (action.type) {
    case ADMIN_LOGOUT:
      return initialState();
    case ADMIN_UPDATE_PROFILE:
      return {
        ...state,
        profile: action.profile,
        token: action.token,
      };
    case ADMIN_LOGIN_HANDLE:
      return {
        ...state,
        ...action.value
      };
    case ADMIN_TOGGLE_PROFILE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
}
