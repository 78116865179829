import { defineMessages } from 'react-intl';
import { CHARACTER_STATS as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  budget: {
    id: `${idPrefix}.budget`,
    defaultMessage: 'Choose your character'
  },
  legality: {
    id: `${idPrefix}.legality`,
    defaultMessage: 'Client'
  },
  rating: {
    id: `${idPrefix}.rating`,
    defaultMessage: 'Journalist'
  },
  securityDefault: {
    id: `${idPrefix}.securityDefault`,
    defaultMessage: 'Influence'
  },
  securityUser: {
    id: `${idPrefix}.securityUser`,
    defaultMessage: 'Privacy'
  }
});

export default messages;
