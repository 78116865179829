import { CHARACTER_CHOOSE_VIEW as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.title`]: 'Оберіть героя',
  [`${idPrefix}.characterUser`]: 'Користувач',
  [`${idPrefix}.characterJournalist`]: 'Журналіст',
  [`${idPrefix}.characterBlogger`]: 'Блогер',
  [`${idPrefix}.goNextStep`]: 'Почати гру',
};

export default messages;
