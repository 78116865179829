import { ADMIN_SIGN_IN as idPrefix } from '../../../constants/translates';

const messages = {
  [`${idPrefix}.greeting`]: 'Вход в админ панель',
  [`${idPrefix}.formEmailLabel`]: 'Введите свой email',
  [`${idPrefix}.formPasswordLabel`]: 'Введите свой пароль',
  [`${idPrefix}.formSubmitButton`]: 'Ввойти'
};

export default messages;
