import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FinanceIcon from '../../../assets/images/icons/budget.svg';
import LegalityIcon from '../../../assets/images/icons/legality.svg';
import PopularityIcon from '../../../assets/images/icons/rating.svg';
import SecurityIcon from '../../../assets/images/icons/security.svg';
import GameStatsListItem from './GameStatsListItem';
import characterStatsMessages from '../../translates/global/characterStats.i18n';

function GameStatsList({
  className, ratingChanges, stats, userCharacter
}) {
  return (
    <div className={`App__gameStatsList ${className}`}>
      {
        stats.security
        && (
          <GameStatsListItem
            stat={stats.security}
            statChanges={ratingChanges.security}
            statName={(
              userCharacter
                ? (<FormattedMessage {...characterStatsMessages.securityUser} />)
                : (<FormattedMessage {...characterStatsMessages.securityDefault} />)
            )}
            statImg={SecurityIcon}
          />
        )
      }
      {
        stats.rating
        && (
          <GameStatsListItem
            stat={stats.rating}
            statChanges={ratingChanges.rating}
            statName={(<FormattedMessage {...characterStatsMessages.rating} />)}
            statImg={PopularityIcon}
          />
        )
      }
      {
        stats.legality
        && (
          <GameStatsListItem
            stat={stats.legality}
            statChanges={ratingChanges.legality}
            statName={(<FormattedMessage {...characterStatsMessages.legality} />)}
            statImg={LegalityIcon}
          />
        )
      }
      {
        stats.budget
        && (
          <GameStatsListItem
            stat={stats.budget}
            statChanges={ratingChanges.budget}
            statName={(<FormattedMessage {...characterStatsMessages.budget} />)}
            statImg={FinanceIcon}
          />
        )
      }
    </div>
  );
}

GameStatsList.propTypes = {
  className: PropTypes.string,
  ratingChanges: PropTypes.shape({
    security: PropTypes.number,
    rating: PropTypes.number,
    legality: PropTypes.number,
    budget: PropTypes.number
  }),
  stats: PropTypes.shape({
    security: PropTypes.number,
    rating: PropTypes.number,
    legality: PropTypes.number,
    budget: PropTypes.number
  }).isRequired,
  userCharacter: PropTypes.bool
};

GameStatsList.defaultProps = {
  className: '',
  ratingChanges: {},
  userCharacter: false
};

export default GameStatsList;
