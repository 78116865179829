import { Card } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

export default function CharactersListItem({
  character, characterSelect, selectedCharacter
}) {
  const { id, image, name } = character;
  return (
    <Card
      className={`charactersList__item ${selectedCharacter.id === id ? 'charactersList__item__selected' : ''}`}
      onClick={() => characterSelect(character)}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      <div className="charactersList__item__image">
        {
          image.path
          && (<img src={image.path} alt={name} />)
        }
      </div>
      <div className="charactersList__item__name">
        {name}
      </div>
    </Card>
  );
}

CharactersListItem.propTypes = {
  character: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    rating_id: PropTypes.number.isRequired,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired
    }),
    rating: PropTypes.shape({
      id: PropTypes.number.isRequired,
      budget: PropTypes.number,
      legality: PropTypes.number,
      rating: PropTypes.number,
      security: PropTypes.number
    })
  }).isRequired,
  characterSelect: PropTypes.func.isRequired,
  selectedCharacter: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};
