import { notification } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  HIDE_ERROR, SHOW_ERROR, TOGGLE_PRELOADER
} from '../constants/actionTypes';
import messages from './page.i18n';

export function togglePreloader(preloaderStatus) {
  return { type: TOGGLE_PRELOADER, preloaderStatus };
}

export function hideError() {
  return {
    type: HIDE_ERROR,
    error: {
      message: '',
      status: false
    }
  };
}

export function showError(errorStatus) {
  let errorMessage;

  switch (errorStatus) {
    case 422:
      errorMessage = <FormattedMessage {...messages.authError} />;
      break;
    case 500:
      errorMessage = <FormattedMessage {...messages.serverError} />;
      break;
    default:
      errorMessage = <FormattedMessage {...messages.defaultError} />;
      break;
  }

  notification.error({
    message: (<FormattedMessage {...messages.title} />),
    description: errorMessage
  });

  return { type: SHOW_ERROR };
}
