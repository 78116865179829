import React from 'react';
import PropTypes from 'prop-types';

function GameStatsMiniListItem({ stat, statImg, statName }) {
  const progressBarClass = stat > 49
    ? 'App__gameStatsMiniList__item__progressBar__positive'
    : 'App__gameStatsMiniList__item__progressBar__negative';
  return (
    <div className="App__gameStatsMiniList__item">
      <div
        className={`App__gameStatsMiniList__item__progressBar ${progressBarClass}`}
      >
        <div
          className="App__gameStatsMiniList__item__progressBar__bar"
          style={{
            height: `${stat}%`
          }}
        />
        <div className="App__gameStatsMiniList__item__progressBar__value">
          {stat}
        </div>
      </div>
      {
        statImg
        && (
          <div className="App__gameStatsMiniList__item__icon">
            <img src={statImg} alt={statName} />
          </div>
        )
      }
      <div className="App__gameStatsMiniList__item__name">
        {statName}
      </div>
    </div>
  );
}

GameStatsMiniListItem.propTypes = {
  stat: PropTypes.number.isRequired,
  statImg: PropTypes.node.isRequired,
  statName: PropTypes.node.isRequired
};

export default GameStatsMiniListItem;
