import React, { Component } from 'react';
import {
  Switch, Router, Route, Redirect
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Page from './components/layout/Page/Page';
import './scss/App.scss';
import {
  CHOOSE_CHARACTER, GAME_IN_PROGRESS, GAME_IS_OVER
} from './components/constants/gameSteps';
import Rules from './components/pages/Rules/Rules';
import Game from './components/pages/Game/Game';
import Results from './components/pages/Results/Results';
import CharacterChoose from './components/pages/CharacterChoose/CharacterChoose';
import AdminSignIn from './components/pages/AdminSignIn/AdminSignIn';
import AdminGamesList from './components/pages/AdminGamesList/AdminGamesList';

class App extends Component {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
  }

  render() {
    return (
      <Router history={this.history}>
        <Page>
          <Switch>
            <Route exact strict path="/" component={Rules} />
            <Route exact strict path={`/${CHOOSE_CHARACTER}`} component={CharacterChoose} />
            <Route exact strict path={`/${GAME_IN_PROGRESS}`} component={Game} />
            <Route exact strict path={`/${GAME_IS_OVER}`} component={Results} />
            <Redirect exact from="/admin" to="/admin/sign-in" />
            <Route exact strict path="/admin/sign-in" component={AdminSignIn} />
            <Route exact strict path="/admin/games-list/:status/:page" component={AdminGamesList} />
          </Switch>
        </Page>
      </Router>
    );
  }
}

export default App;
